define("monitor-ui/routes/application", ["exports", "@babel/runtime/helpers/esm/decorate", "@babel/runtime/helpers/esm/getPrototypeOf", "@babel/runtime/helpers/esm/get", "@ember-decorators/service", "@ember-decorators/object", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _decorate2, _getPrototypeOf2, _get2, _service, _object, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ApplicationRoute = (0, _decorate2.default)(null, function (_initialize, _EmberRoute$extend) {
    class ApplicationRoute extends _EmberRoute$extend {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: ApplicationRoute,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "currentUser",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "intl",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "theme",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "raven",
        value: void 0
      }, {
        kind: "field",
        key: "tcbToken",

        value() {
          return null;
        }

      }, {
        kind: "method",
        key: "beforeModel",
        value: function beforeModel(transition) {
          if (window.location.href.indexOf('abb') > -1) {
            //TODO: load from company settings
            this.theme.setCompanySettings({
              id: 814,
              //abb company harcoded id
              logo: '/assets/logo/abb_logo.svg',
              navType: 'light',
              title: 'ABB - Monitoring'
            });
          } // this.intl.setLocale('en')
          // this.moment.setLocale('en')


          (0, _get2.default)((0, _getPrototypeOf2.default)(ApplicationRoute.prototype), "beforeModel", this).apply(this, arguments);

          if ('tcbToken' in transition.queryParams) {
            // If the session is not already authenticated the transition is aborted and retried by ember-simple-auth
            // once the authentication is successful. To avoid an infinite loop the auth token is stored and further
            // calls to beforeModel will skip auth with identical tokens.
            const currentTcbToken = this.tcbToken;

            if (transition.queryParams.tcbToken !== currentTcbToken) {
              this.set('tcbToken', transition.queryParams.tcbToken);

              if ('tcbRefreshToken' in transition.queryParams) {
                window.localStorage.setItem('jwtRefreshToken', transition.queryParams.tcbRefreshToken);
              }

              if (!this.get('session.isAuthenticated')) {
                // attemptedTransition is used by the sessionAuthenticationSucceeded hook to redirect the user after login.
                // The hook is not triggered at the end of session.authenticate if the session is already authenticated.
                this.get('session').set('attemptedTransition', transition);
                transition.abort();
              }

              return this.get('session').authenticate('authenticator:token', {
                sessionToken: transition.queryParams.tcbToken
              }).catch(err => {
                if (err && err.responseText) {
                  this.raven.captureMessage(err.responseText);
                }

                Ember.run.later(() => {
                  this.sessionInvalidated();
                }, 1000);
              });
            }
          }

          return this._loadCurrentUser();
        }
      }, {
        kind: "method",
        key: "redirect",
        value: function redirect() {// this.transitionTo('app.projects.list')
        }
      }, {
        kind: "method",
        key: "sessionAuthenticated",
        value: function sessionAuthenticated() {
          (0, _get2.default)((0, _getPrototypeOf2.default)(ApplicationRoute.prototype), "sessionAuthenticated", this).apply(this, arguments);

          this._loadCurrentUser();
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "invalidateSession",
        value: function invalidateSession() {
          this.session.invalidate();
        }
      }, {
        kind: "method",
        key: "_loadCurrentUser",
        value: function _loadCurrentUser() {
          return this.currentUser.load().catch(() => this.session.invalidate());
        }
      }]
    };
  }, Ember.Route.extend(_applicationRouteMixin.default));
  _exports.default = ApplicationRoute;
});