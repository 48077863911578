define("monitor-ui/router", ["exports", "monitor-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    sessionContext: Ember.inject.service(),

    didTransition() {
      this._super(...arguments);

      this._trackPage();
    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = this.url;
        const title = this.getWithDefault('currentRouteName', 'unknown');
        const user = this.sessionContext.currentUser;

        if (user.get('trackingConsent')) {
          this.metrics.trackPage({
            page,
            title
          });
        }
      });
    }

  });
  Router.map(function () {
    this.route('index', {
      path: '/'
    });
    this.route('login');
    this.route('otp-login');
    this.route('recover');
    this.route('error');
    this.route('app', function () {
      this.route('projects', function () {
        this.route('project', {
          path: '/:project_id'
        }, function () {
          this.route('reports', function () {
            this.route('report', {
              path: '/:report_id'
            });
          });
          this.route('evaluations', function () {
            this.route('evaluation', {
              path: '/:evaluation_session_id'
            });
            this.route('evaluation-details', {
              path: '/:evaluation_session_id/details'
            });
            this.route('list');
            this.route('evaluation-results', {
              path: '/:evaluation_session_id/results'
            });
          });
          this.route('surveys');
          this.route('sessions');
          this.route('edit');
        });
        this.route('list');
        this.route('new');
      });
      this.route('account', function () {
        this.route('password');
        this.route('profile');
      }); // this.route('dashboard');

      this.route('surveys', function () {
        this.route('survey', {
          path: '/:survey_id'
        }, function () {
          this.route('questions', {
            path: 'questions'
          });
        });
        this.route('list');
      });
      this.route('organisation', function () {
        this.route('dimensions');
      });
      this.route('organisation-dashboard');
    });
    this.route('new_password');
  });
  var _default = Router;
  _exports.default = _default;
});