define("monitor-ui/controllers/app", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object/computed"], function (_exports, _decorate2, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppController,
      d: [{
        kind: "field",
        key: "collapsed",

        value() {
          return true;
        }

      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "theme",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _computed.alias)('sessionContext.currentUser')],
        key: "user",
        value: void 0
      }]
    };
  }, Ember.Controller);
  _exports.default = AppController;
});